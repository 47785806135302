import { render, staticRenderFns } from "./ScienceServicePay.vue?vue&type=template&id=2edf7811&scoped=true&"
import script from "./ScienceServicePay.vue?vue&type=script&lang=js&"
export * from "./ScienceServicePay.vue?vue&type=script&lang=js&"
import style0 from "./ScienceServicePay.vue?vue&type=style&index=0&id=2edf7811&scoped=true&lang=scss&"
import style1 from "./ScienceServicePay.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2edf7811",
  null
  
)

export default component.exports